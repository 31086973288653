







































import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

type Post = {
  id: number;
  author: string;
  url_avatar_author?: string;
  url_upload?: string;
  description: string;
};

@Component({
  filters: {
    created_at(value, appLanguage) {
      if (!value) return;

      return moment(value)
        .locale(appLanguage)
        .format("Do MMMM, Y");
    },
    created_at_from_now(value, appLanguage) {
      if (!value) return;

      return moment(value)
        .locale(appLanguage)
        .fromNow();
    }
  }
})
export default class PostComponent extends Vue {
  @Prop(Object) readonly post!: Post;

  get appData() {
    return this.$store.getters["app/data"];
  }

  get appLayout() {
    try {
      return this.appData.layout;
    } catch (e) {
      return null;
    }
  }

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get authorInitials() {
    return this.post.author.substr(0, 2).toUpperCase();
  }

  get urlAvatar() {
    return this.post.url_avatar_author;
  }

  get urlUpload() {
    return this.post.url_upload;
  }

  get shortDescription() {
    const description = this.post.description;
    return description?.length > 150 ? `${description.substr(0, 150)}...` : description;
  }
}
